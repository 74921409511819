
// Changes orders of blocks with delivary date on Zakelik page

module.exports = function zakelijkPlaceholderDeliveryDate() {
  (function($) {

    if ($('.deliver-date-placeholder').length > 0 && $('.delivery-date-zakelijk').length > 0) {
        $('.deliver-date-placeholder').html($('.delivery-date-zakelijk'));
    }

  })(jQuery);
}
