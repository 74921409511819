
// Put popups on Zakeliik page in fancybox popup module

module.exports = function zakelijkPopupFancybox() {
  (function($) {
    $('a.zakelijk-popup').fancybox({
        autoWidth: true,
        autoHeight: true,
        wrapCSS: 'zakelijk-popup-container',
        maxWidth: 724,
        beforeShow: function () {
            var form = $(this)[0].content;
            var button = form.find('.form-actions');
            form.find('.webform-component--kunnen').before(form.find('.form-actions'));
        }
    });
  })(jQuery);
}
