
var bestellenSidebarPosition = require('./bestellenSidebarPosition');

// Set position of sidebar on giftcard checkout step 2 after some changes on input elements

module.exports = function afterChangesInputPositionSidebarCheckout() {
  (function($) {
    $('section').on('change', function() {
      bestellenSidebarPosition();
    });
  })(jQuery);
}
