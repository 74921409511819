
//Scrollig of sidebar on bestellen page on LG+

module.exports = function bestellenSidebarScroll() {
  (function($) {

    var bestellenForm = $('#giftcard-extra-form');
    if ( !bestellenForm.length ) { return; }

    var sidebar = $('#edit-cart-sidebar');
    var sidebarPaddingTopOnScroll = 20;

    var minScroll = bestellenForm.offset().top - sidebarPaddingTopOnScroll;
    var maxScroll = minScroll + bestellenForm.height() - sidebar.outerHeight();

    if ( $('body').hasClass('mq-lg') || $('body').hasClass('mq-xl') ) {

        if ($(window).scrollTop() > minScroll && $(window).scrollTop() < maxScroll) {
            sidebar.css('top', $(window).scrollTop() - bestellenForm.offset().top);

            var rightPosition = ( ($('#page').width() - $('#main-content').width())  / 2);
            sidebar.css({
                "position": "fixed",
                "top": sidebarPaddingTopOnScroll,
                "right": rightPosition + "px"
            });
        }

        if ($(window).scrollTop() > maxScroll) {
            sidebar.css({
                "position": "absolute",
                "top": bestellenForm.outerHeight() - sidebar.outerHeight(),
                "right": 0
            })
        }

        if ($(window).scrollTop() < minScroll) {
            sidebar.css({
                "position": "absolute",
                "top": 0,
                "right": 0
            })
        }
    } else {
        sidebar.attr("style", "");
    }

  })(jQuery);
}
