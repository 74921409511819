
// Set position of sidebar on giftcard checkout step 2

module.exports = function bestellenSidebarPosition() {
  (function($) {

    if ( $('body').hasClass('page-giftcards-shipping') || $('body').hasClass('page-giftcards-overview') ) {
        var sidebar = $('.giftcard-pane-cart-sidebar');
        var region = $('.region-content');

        if ( $('body').hasClass('mq-lg') || $('body').hasClass('mq-xl') ) {
            sidebar.css('margin-top', 0);

            var sidebarOffset = sidebar.offset();
            var regionOffset = region.offset();

            var margin = regionOffset.top + parseInt(region.css('padding-top')) - sidebarOffset.top;

            sidebar.css('margin-top', margin);
        } else {
            sidebar.css('margin-top', 0);
        }
    }

  })(jQuery);
}
